import React, { useEffect, useState } from "react"
import Img from "gatsby-image"
import tw, { styled } from "twin.macro"
import Layout from "../components/layout"
import { Link } from "gatsby"
import {
  HeaderWrap,
  SubHeader,
  Header,
  ProductWrap,
  ProductCard,
} from "../components/productListStyles"
// import { CartItems } from "../components/cart"
// import { FiShoppingCart } from "react-icons/fi"
import Footer from "../components/Footer"
import BlockContent from "@sanity/block-content-to-react"
// import { useForm, Controller } from "react-hook-form"
import { Helmet } from "react-helmet"
// import ReactDatePicker from "react-datepicker"
// import "react-datepicker/dist/react-datepicker.css"
import Slider from "react-animated-slider"
import "react-animated-slider/build/horizontal.css"
import DesignerForm from "../components/designerForm"

const CartButton = styled.div`
  position: fixed;
  top: 50px;
  right: 15px;
  @media (min-width: 1024px) {
    top: 15px;
    right: 30px;
  }
  z-index: 6000;
  button {
    &:hover {
      cursor: pointer;
    }
    background: none;
    border: none;
    &:focus {
      outline: none;
    }
  }
`

const About = styled.p`
  font-size: 1.25rem;
  line-height: 1.625;
  font-weight: 300;
  width: 75%;
  margin: 0px auto 100px auto;
  text-align: center;
  @media (max-width: 600px) {
    padding-top: 75px;
  }
  @media (max-width: 700px) {
    font-size: 16px;
  }
`

const ProcessTitle = styled.h2`
  text-align: center;
  font-size: 36px;
  font-family: "Playfair Display";
`

const serializers = {
  types: {
    code: props => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    ),
  },
}

const Concepts = ({ data, location }) => {
  return (
    <Layout location={location}>
      {/* <Helmet>
        <script
          src="https://widget.cloudinary.com/v2.0/global/all.js"
          type="text/javascript"
        ></script>
      </Helmet> */}
      {/* <CartItems close={close} location={location} />
      <CartButton>
        <button onClick={toggleCart}>
          <FiShoppingCart size={25} />
        </button>
      </CartButton> */}
      <HeaderWrap>
        {data.sanityConceptsPage.header.map((head, i) => {
          return (
            <Header key={i} color={head.myColor.hex}>
              {head.title}
            </Header>
          )
        })}
      </HeaderWrap>
      <SubHeader>{data.sanityConceptsPage.subHeader}</SubHeader>
      <About>{data.sanityConceptsPage.aboutConcepts}</About>
      <ProcessTitle>{data.sanityConceptsPage.processTitle}</ProcessTitle>
      <Diagram>
        {data.sanityConceptsPage._rawDiagramItems.map(item => {
          return (
            <div className="itemDiv">
              <Number>{item.number} .</Number>

              <ItemGrid>
                <div className="textBlock">
                  <BlockContent
                    blocks={item.itemTitle}
                    serializers={serializers}
                  />
                  <div className="desc">
                    <BlockContent
                      blocks={item.itemDesc}
                      serializers={serializers}
                    />
                  </div>
                </div>
                {item.imageLayout === "justifiedRight" && (
                  <ImageRight src={item?.itemImage?.asset?.url} />
                )}
                {item.imageLayout === "justifiedBottom" && (
                  <ImageBottom
                    widthProps={
                      item?.itemImage?.asset?.metadata?.dimensions?.width / 2
                    }
                    src={item?.itemImage?.asset?.url}
                  />
                )}
              </ItemGrid>
            </div>
          )
        })}
      </Diagram>
      <ProcessTitle>{data.sanityConceptsPage.examplesTitle}</ProcessTitle>
      <ProductWrap>
        {data.allSanityConcepts.edges.map(({ node: concept }, i) => {
          return (
            <Link
              key={i}
              to={`/concepts/${concept.name.replace(/\s/g, "-").toLowerCase()}`}
            >
              <ProductCard>
                <Img
                  fluid={
                    concept.featureImage && concept.featureImage.asset.fluid
                  }
                />
                <h3>{concept.name}</h3>
              </ProductCard>
            </Link>
          )
        })}
      </ProductWrap>
      <SliderWrap>
        <ProcessTitle>{data.sanityConceptsPage.testimonialsTitle}</ProcessTitle>

        <Slider touchDisabled={true} autoplay={7500}>
          {data.sanityConceptsPage.testimonials.map((slide, index) => (
            <div key={index}>
              <div>{slide.testimonial}</div>
              <h2>{slide.name}</h2>
            </div>
          ))}
        </Slider>
      </SliderWrap>
      {/* <button onClick={clickMe}>test</button> */}
      <ProcessTitle>{data.sanityConceptsPage.formTitle}</ProcessTitle>
      <DesignerForm location={location} />

      <Footer />
    </Layout>
  )
}

export default Concepts

export const query = graphql`
  query ConceptsQuery {
    allSanityConcepts {
      edges {
        node {
          name
          featureImage {
            asset {
              fluid(maxWidth: 700) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
    sanityConceptsPage {
      header {
        title
        myColor {
          hex
        }
      }
      formTitle
      aboutConcepts
      subHeader
      processTitle
      examplesTitle
      testimonialsTitle
      testimonials {
        name
        testimonial
      }
      _rawDiagramItems(resolveReferences: { maxDepth: 10 })
    }
  }
`

const Number = styled.h3`
  font-size: 48px;
  font-weight: 900;
  font-family: "Playfair Display";
  transform: translateX(-25px);
`

const Diagram = styled.div`
  background: #f2f1ef;
  padding-left: 100px;
  padding-top: 50px;
  padding-bottom: 25px;
  width: 85%;
  margin: 50px auto 150px auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 1600px) {
    display: block;
    width: 75%;
    padding-right: 0;
  }
  @media (max-width: 1200px) {
    width: 75%;
  }
  @media (max-width: 900px) {
    padding-left: 75px;
    width: 90%;
  }
  @media (max-width: 550px) {
    width: 100%;
    padding-left: 50px;
    padding-right: 25px;
    img {
      width: 75%;
      margin: 0 auto;
      display: block;
    }
  }
  .itemDiv {
    height: 400px;
    //border: 1px solid #000;
    //background: lightcyan;
    position: relative;
    @media (max-width: 1600px) {
      height: initial;
    }
    h3 {
      padding: 0;
      margin: 0;
    }
  }
  div {
    position: relative;
    .desc {
      p,
      li {
        font-style: italic;
      }
    }
  }
`

const ImageRight = styled.img`
  /* position: absolute;
  top: 55%;
  right: 25%; */
  @media (max-width: 1150px) {
    margin: 0 auto;
    display: block;
  }
`

const ItemGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 1150px) {
    display: block;
  }
`

const ImageBottom = styled.img`
  /* left: 25%;
  margin-left: ${({ widthProps }) => `${-widthProps}px`};
  position: absolute;
  margin: 0 auto;
  bottom: -200px; */
  /* display:block; */
  justify-self:center;
  grid-column: 1 / -1;
  @media (max-width: 1150px) {
    margin: 0 auto;
    display: block;
  }
`

const SliderWrap = styled.div`
  width: 70%;
  h2 {
    width: 75%;
    margin: 0 auto;
    margin-top: 10px;
  }
  @media (max-width: 1280px) {
    width: 90%;
  }
  @media (max-width: 750px) {
    width: 100%;
    margin: 100px auto 0px auto;
  }

  margin: 100px auto 100px auto;
  text-align: center;
  .slider {
    height: 325px;
    margin-top: 50px;
    @media (max-width: 900px) {
      height: 400px;
    }
    @media (max-width: 500px) {
      height: 490px;
      margin-top: 50px;
    }
  }
  .slide {
    div {
      width: 80%;
      margin: 0 auto;
      font-size: 1.25rem;
      line-height: 1.625;
      font-weight: 300;
      font-family: Raleway, arial;
      font-style: italic;
      @media (max-width: 700px) {
        font-size: 16px;
      }
      @media (max-width: 750px) {
        width: 66%;
      }
      @media (max-width: 665px) {
        width: 300px;
      }
      @media (max-width: 500px) {
        width: 250px;
      }
      @media (max-width: 430px) {
        width: 280px;
      }
    }
  }
  .slider a.previousButton,
  .slider a.nextButton {
    top: 20%;
    svg polygon {
      fill: lightgrey;
    }
  }
`
